import { Divider, Text } from '@mantine/core';
import { Card } from './Card';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES_LIST } from 'types';

export const UserToUpgradeSubscription = ({
  activity,
}: {
  activity: string;
}) => {
  const { t } = useTranslation();
  return (
    <Card>
      <Text p={20}>
        {activity}{' '}
        <Link to={APP_ROUTES_LIST.HEALTHPLANS}>
          {t('activity.ClickHereToUpgrade')}
        </Link>
      </Text>
      <Divider />
    </Card>
  );
};
