import {
  useGetActivePlansByUserIdQuery,
  useGetHealthAndPlanDurationsQuery,
  useGetPlanBeneficiariesByUserPlanIdQuery,
} from 'services/api/plan';
import { useGetAccountQuery } from 'services/api/account';
import { useEffect } from 'react';

export const useUserPlansData = () => {
  const { data: currentUser } = useGetAccountQuery();
  const {
    data: { healthPlans = [], planDurations = [] } = {},
    isLoading: isHealthAndPlanDurationsLoading,
  } = useGetHealthAndPlanDurationsQuery();

  const {
    data: { userPlans: activeUserPlans = [] } = {},
    isLoading: isActiveUserPlansLoading,
    isSuccess: isActiveUserPlansSuccessful,
    refetch: refetchActiveUserPlans,
  } = useGetActivePlansByUserIdQuery(
    { userId: currentUser?.userId ?? '' },
    { skip: !currentUser?.userId },
  );
  const activeUserPlan = activeUserPlans?.[0];

  // Fetch plan beneficiaries based on active user plan ID
  const {
    data: { planBeneficiaries = [] } = {},
    isLoading: isPlanBeneficiariesLoading,
    isSuccess: isPlanBeneficiarySuccessful,
  } = useGetPlanBeneficiariesByUserPlanIdQuery(
    { userPlanId: activeUserPlan?.planId ?? '' },
    { skip: !activeUserPlan?.planId },
  );
  const currentUserPlanBeneficiaryId = planBeneficiaries?.[0]?.id || null;

  useEffect(() => {
    if (
      isPlanBeneficiarySuccessful &&
      isActiveUserPlansSuccessful &&
      !currentUserPlanBeneficiaryId
    ) {
      refetchActiveUserPlans();
    }
  }, []);

  const {
    data: { userPlans: userPlanHistory = [] } = {},
    isLoading: isUserPlansHistoryLoading,
  } = useGetActivePlansByUserIdQuery(
    { userId: currentUser?.userId ?? '' },
    { skip: !currentUser?.userId },
  );

  return {
    healthPlans,
    activeUserPlans,
    activeUserPlan,
    planDurations,
    planBeneficiaries,
    userPlanHistory,
    currentUserPlanBeneficiaryId,
    isHealthAndPlanDurationsLoading,
    isActiveUserPlansLoading,
    isPlanBeneficiariesLoading,
    isUserPlansHistoryLoading,
    isActiveUserPlansSuccessful,
    isPlanBeneficiarySuccessful,
  };
};
